body {
  overflow: overlay;
}
#edycjaZam {
  background: linear-gradient(45deg, #E9AA01 10%, #E9AA01, transparent 90%);
  margin: 0 0 20px;
  padding: 10px;
  border-radius: 3px;
  max-width: 790px;
}
#edycjaZam .btn.btn-primary {
  background: #e9aa01;
  border-color: #f5db93;
}
#edycjaZam .komu {
  display: inline-block;
  color: white;
  margin-left: 10px;
}
.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23373368' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23373368' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel {
  margin-top: 10px;
  margin-bottom: 5px;
}
.carousel .carousel-indicators {
  visibility: hidden;
}
/*# sourceMappingURL=GlobalStyle.css.map */